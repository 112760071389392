import { defineAbility } from '@casl/ability';

export default function defineAbilityFor(userType: string) {

    return defineAbility((can, cannot) => {
        if (userType === 'proteusAdmin') {
            //PAGES - can this user visit this page?
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_JOBS);
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_USERS);
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_CROWDS);
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_SKILLS);
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_ORGANISATIONS);

            can('enable', 'logs');


            //#region job
            can('read', 'job');
            can('update', 'job');
            can('create', 'job');
            can('delete', 'job');
            can('archive', 'job');

            //#region job detail
            can('add', 'worker');
            can('add', 'crowd');
            can('add', 'data');
            can('delete', 'data')
            //#endregion
            can('archive', 'user');
            can('unarchive', 'user');
            can('resume', 'user');
            can('stop', 'user');
            //#endregion

            //user
            can('read', 'user');
            // can update a user with a lower role
            can('update', 'Admin')
            can('update', 'View Only')
            can('update', 'Worker')
            can('update', 'Crowd Manager')
            can('update', 'TBD')
            can('read', 'proteusWorker');
            can('read', 'proteusCrowdManager');
            can('read', 'proteusViewOnly');
            can('read', 'proteusAdmin');
            can('create', 'user');
            can('delete', 'user');
            can('send', 'activationCode');
            can('pause', 'worker');
            can('delete', 'worker');
            can('pause', 'primaryWorker');
            can('pause', 'qaWorker');
            can('resume', 'primaryWorker');
            can('resume', 'qaWorker');

            //skill
            can('read', 'skill');
            can('update', 'skill');
            can('create', 'skill');
            can('delete', 'skill');

            //#region crowd
            can('read', 'crowd');
            can('update', 'crowd');
            can('create', 'crowd');
            can('delete', 'crowd');
            can('archive', 'crowd');
            can('remove', 'crowdUser');

            //organisation
            can('read', 'organisation');
            can('update', 'organisation');
            can('create', 'organisation');
            can('delete', 'organisation');
            //#endregion
        } else if (userType === 'proteusCrowdManager') {
            //PAGES - can this user visit this page?
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_JOBS);
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_USERS);
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_CROWDS);
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_SKILLS);
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_ORGANISATIONS);

            cannot('enable', 'logs');

            //#region job
            can('read', 'job');
            can('update', 'job');
            can('create', 'job');
            cannot('delete', 'job');
            cannot('archive', 'job');

            //#region job detail
            can('add', 'worker');
            can('add', 'crowd');
            can('add', 'data');
            //#endregion
            //#endregion
            can('archive', 'user');
            can('unarchive', 'user');
            can('resume', 'user');
            cannot('stop', 'user');

            //user
            can('read', 'user');
            // can only update a user with a lower role
            cannot('update', 'Admin')
            can('update', 'View Only')
            can('update', 'Worker')
            can('update', 'Crowd Manager')
            can('read', 'proteusWorker');
            can('read', 'proteusCrowdManager');
            can('read', 'proteusViewOnly');
            cannot('read', 'proteusAdmin');

            cannot('create', 'user');
            cannot('delete', 'user');
            can('pause', 'worker');
            cannot('delete', 'worker');
            cannot('send', 'activationCode');
            can('pause', 'primaryWorker');
            can('pause', 'qaWorker');
            can('resume', 'primaryWorker');
            can('resume', 'qaWorker');
            
            //skill
            can('read', 'skill');
            cannot('update', 'skill');
            cannot('create', 'skill');
            cannot('delete', 'skill');

            //#region crowd
            can('read', 'crowd');
            can('update', 'crowd');
            can('create', 'crowd');
            can('archive', 'crowd');
            can('delete', 'crowd');
            can('remove', 'crowdUser');

            //organisation
            can('read', 'organisation');
            cannot('update', 'organisation');
            cannot('create', 'organisation');
            cannot('delete', 'organisation');
            //#endregion
        } else if (userType === 'proteusViewOnly') {
            //PAGES - can this user visit this page?
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_JOBS);
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_USERS);
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_CROWDS);
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_SKILLS);
            can(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_ORGANISATIONS);

            cannot('enable', 'logs');

            //#region job
            can('read', 'job');
            cannot('update', 'job');
            cannot('create', 'job');
            cannot('delete', 'job');
            cannot('archive', 'job');

            //#region job detail
            // not sure if we want to specify like this or rather just like
            // can "read" "all" or something
            cannot('add', 'worker');
            cannot('add', 'crowd');
            cannot('add', 'data');
            //#endregion

            cannot('archive', 'user');
            cannot('unarhive', 'user');
            cannot('resume', 'user');
            cannot('stop', 'user');
            //#endregion

            //user
            can('read', 'user');
            cannot('update', 'user');
            cannot('create', 'user');
            cannot('delete', 'user');
            cannot('send', 'activationCode');
            cannot('pause', 'worker');
            cannot('delete', 'worker');

            //skill
            can('read', 'skill');
            cannot('update', 'skill');
            cannot('create', 'skill');
            cannot('delete', 'skill');

            //crowd
            can('read', 'crowd');
            cannot('update', 'crowd');
            cannot('create', 'crowd');
            cannot('delete', 'crowd');
            cannot('archive', 'crowd');
            can('cannot', 'crowdUser');
        } else if (userType === 'proteusWorker') {
            //PAGES - can this user visit this page?
            cannot(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_JOBS);
            cannot(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_USERS);
            cannot(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_CROWDS);
            cannot(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_SKILLS);
            cannot(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_ORGANISATIONS);
            
            cannot('enable', 'logs');

            //#region job
            cannot('read', 'job');
            cannot('update', 'job');
            cannot('create', 'job');
            cannot('delete', 'job');
            cannot('archive', 'job');

            //#region job detail
            cannot('add', 'worker');
            cannot('add', 'crowd');
            cannot('add', 'data');
            //#endregion
            //#endregion

            cannot('archive', 'user');
            cannot('unarhive', 'user');
            cannot('resume', 'user');
            cannot('stop', 'user');

            //user
            cannot('read', 'user');
            cannot('update', 'user');
            cannot('create', 'user');
            cannot('delete', 'user');
            cannot('send', 'activationCode');
            cannot('pause', 'worker');
            cannot('delete', 'worker');

            //skill
            cannot('read', 'skill');
            cannot('update', 'skill');
            cannot('create', 'skill');
            cannot('delete', 'skill');

            //crowd
            cannot('read', 'crowd');
            cannot('update', 'crowd');
            cannot('create', 'crowd');
            cannot('delete', 'crowd');
            cannot('archive', 'crowd');
            cannot('remove', 'crowdUser');
        } else {
            //no userType - just disabling all
            //PAGES - can this user visit this page?
            cannot(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_JOBS);
            cannot(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_USERS);
            cannot(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_CROWDS);
            cannot(ABILITY_TYPES.VISIT, ABILITY_PAGES.ADMIN_SKILLS);

            cannot('enable', 'logs');

            cannot('read', 'job');
            cannot('update', 'job');
            cannot('create', 'job');
            cannot('delete', 'job');
            cannot('archive', 'job');

            //user
            cannot('read', 'user');
            cannot('update', 'user');
            cannot('create', 'user');
            cannot('delete', 'user');
            cannot('send', 'activationCode');
            cannot('pause', 'worker');
            cannot('delete', 'worker');

            //skill
            cannot('read', 'skill');
            cannot('update', 'skill');
            cannot('create', 'skill');
            cannot('delete', 'skill');

            //crowd
            cannot('read', 'crowd');
            cannot('update', 'crowd');
            cannot('create', 'crowd');
            cannot('delete', 'crowd');
            cannot('archive', 'crowd');
            cannot('remove', 'crowdUser');
        }
    });
}

export const enum ABILITY_PAGES {
    //ADMIN
    ADMIN_JOBS = 'adminJobs',
    ADMIN_JOB_DETAIL = 'adminJobDetail', //TODO
    ADMIN_USER_JOB_DETAIL = 'adminUserJobDetail', //TODO

    ADMIN_USERS = 'adminUsers',
    ADMIN_USER_DETAIL = 'adminUserDetail', //TODO

    ADMIN_CROWDS = 'adminCrowds',
    ADMIN_CROWD_DETAIL = 'adminCrowdDetail', //TODO

    ADMIN_SKILLS = 'adminSkills',

    ADMIN_ORGANISATIONS = 'adminOrganisations',
    //WORKER
    WORKER_JOBS = 'workerJobs', //TODO
    WORKER_HOME = 'workerHome', //TODO
    WORKER_ACTIVE_JOB = 'workerActiveJob', //TODO


}

export const enum ABILITY_TYPES {
    VISIT = 'visit',

    READ = 'read',//TODO
    UPDATE = 'update',//TODO
    CREATE = 'create',//TODO
    DELETE = 'delete',//TODO
    ARCHIVE = 'archive',//TODO
    UNARCHIVE = 'unarchive',//TODO
    SEND = 'send',//TODO
    PAUSE = 'pause',//TODO
    STOP = 'stop',//TODO
    RESUME = 'resume',//TODO
}
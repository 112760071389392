/* eslint-disable camelcase */
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { _getCognitoPool } from "./_getCognitoPool";
import { _getCognitoUser } from "./_getCognitoUser";

function asyncActivateUser(
  cognitoUser: CognitoUser,
  cognitoAuthenticationDetails: AuthenticationDetails,
  firstName: string,
  lastName: string,
  newPassword: string
) {
  return new Promise(function (resolve, reject) {
    cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
      onSuccess: (e: any) => resolve("alreadyActive"), // Not looking to authenticate, but rather register, thus don't authenticate - it should go directly into 'new Password required' callback
      onFailure: reject,
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        // the api doesn't accept this fields back (as it already exists)
        delete userAttributes.email_verified;
        delete userAttributes.email;

        userAttributes.family_name = lastName || "Anonymous";
        userAttributes.given_name = firstName || "Anonymous";
        cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
          onSuccess: resolve,
          onFailure: reject,
        });
      },
    });
  });
}

interface RegisterDataProps {
  email: string;
  oldPassword: string;
  newPassword: string;
  firstName: string;
  lastName: string;
}

const cognitoRegistration = async (registerData: RegisterDataProps) => {
  const { email, oldPassword, newPassword, firstName, lastName } = registerData;
  const authenticationData = { Username: email.trim(), Password: oldPassword };
  const authDetails = new AuthenticationDetails(authenticationData);
  const cognitoUser = _getCognitoUser(email.trim())
  cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");

  try {
    const result: any = await asyncActivateUser(
      cognitoUser,
      authDetails,
      firstName.trim(),
      lastName.trim(),
      newPassword.trim()
    );
    console.log(result);

    if (result == "alreadyActive") {
      // throw new Error("This user has already been activated");
      return { error: true, message: "This user has already been activated" };
    }
    return { error: false, message: '' };
  } catch (error: any) {
    console.log(error.message);
    return { error: true, message: error.message };
  }
};

export { cognitoRegistration };

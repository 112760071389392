import { FC, Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from 'src/Guards/authGuard/AuthGuard';
import SuspenseLoader from 'src/components/SuspenseLoader';
import AppBarLayout from 'src/layouts/AppBarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import Unverified from 'src/pages/Fallbacks/Status/Unverified/Unverified';
import pages from './routes';

const Loader = (Component: FC) => (props: any) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

// Pages
//Skills
const SkillsPage = Loader(lazy(() => import('src/pages/Admin/Skills/Skills')));
//Organisations
const OrganisationsPage = Loader(lazy(() => import('src/pages/Admin/Organisations/Organisations')));
//Crowds
const CrowdsPage = Loader(lazy(() => import('src/pages/Admin/Crowds/Crowds')));
const CrowdDetailPage = Loader(lazy(() => import('src/pages/Admin/CrowdDetail/CrowdDetail')));
//Jobs
const JobsPage = Loader(lazy(() => import('src/pages/Admin/Jobs/Jobs')));
const JobDetailPage = Loader(lazy(() => import('src/pages/Admin/JobDetail/JobDetail')));
const JobCreatePage = Loader(lazy(() => import('src/pages/Admin/JobCreate/JobCreate')));
const JobWorkerDetailPage = Loader(lazy(() => import('src/pages/Admin/JobWorkerDetail/JobWorkerDetail')));
//Users
const UsersPage = Loader(lazy(() => import('src/pages/Admin/Users/Users')));
const UserDetailPage = Loader(lazy(() => import('src/pages/Admin/UserDetail/UserDetail')));
//Auth
const Register = Loader(lazy(() => import('src/pages/Register/Register')));
const Login = Loader(lazy(() => import('src/pages/Login/Login')));
const ResetPassword = Loader(lazy(() => import('src/pages/ResetPassword/ResetPassword')));
//Worker Jobs
const WorkerHomePage = Loader(lazy(() => import('src/pages/Worker/WorkerHome/WorkerHome')));
//Worker Jobs
const WorkerJobsPage = Loader(lazy(() => import('src/pages/Worker/WorkerJobs/WorkerJobs')));
//Worker Active Job
const WorkerActiveJobPage = Loader(lazy(() => import('src/pages/Worker/WorkerActiveJob/WorkerActiveJob')));

// Status
const Status404 = Loader(lazy(() => import('src/pages/Fallbacks/Status/Status404/Status404')));
const Status500 = Loader(lazy(() => import('src/pages/Fallbacks/Status/Status500/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/pages/Fallbacks/Status/ComingSoon/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/pages/Fallbacks/Status/Maintenance/Maintenance')));
const StatusSuccess = Loader(lazy(() => import('src/pages/Fallbacks/Status/Success/Success')));
const StatusFailure = Loader(lazy(() => import('src/pages/Fallbacks/Status/Failure/Failure')));
const StatusCancel = Loader(lazy(() => import('src/pages/Fallbacks/Status/Cancel/Cancel')));

const routes: RouteObject[] = [
    {
        path: '',
        element: <BaseLayout />,
        /**
        * All children within this element will NOT have a Sidebar and top Navbar
        * All children within this element does not need to be authenticated to access
        */
        children: [
            //#region Base
            {
                //Navigate to home when base routed to base path
                path: '/',
                element: <Navigate to={pages.admin.jobs.path} replace />,
            },
            //#endregion Base
            //#region Auth
            {
                //All authentication routes
                //No navbars are shown as the user is not logged in
                path: pages.auth.root,
                children: [
                    {
                        path: '',
                        element: <Login />,
                    },
                    {
                        path: pages.auth.login.name,
                        element: <Login />,
                    },
                    {
                        path: pages.auth.register.name,
                        element: <Register />,
                    },
                    {
                        path: pages.auth.resetPassword.name,
                        element: <ResetPassword />,
                    },
                ]
            },
            //#endregion Auth
            //#region Status
            {
                //All status routes
                path: pages.status.root,
                children: [
                    {
                        path: '',
                        element: <Navigate to="404" replace />,
                    },
                    {
                        path: pages.status.unverified.name,
                        element: <Unverified />,
                    },
                    {
                        path: pages.status.status404.name,
                        element: <Status404 />,
                    },
                    {
                        path: pages.status.status500.name,
                        element: <Status500 />,
                    },
                    {
                        path: pages.status.statusMaintenance.name,
                        element: <StatusMaintenance />,
                    },
                    {
                        path: pages.status.statusComingSoon.name,
                        element: <StatusComingSoon />,
                    },
                    //TODO: Maybe make paths that shows status within the sidebars as well
                    {
                        path: pages.status.statusSuccess.name,
                        element: <StatusSuccess />,
                    },
                    {
                        path: pages.status.statusFailure.name,
                        element: <StatusFailure />,
                    },
                    {
                        path: pages.status.statusCancel.name,
                        element: <StatusCancel />,
                    },
                ],
            },
            //#endregion Status
            //#region NotFound
            {
                path: '*',
                element: <Status404 />,
            },
            //#endregion NotFound
        ],
    },
    {
        path: '',
        element: (
            /**
             * All children with this element will have a Sidebar and top Navbar
             * AuthGuard checks that the user is logged in before granting access to its children pages
             */
            <AuthGuard>
                <AppBarLayout />
            </AuthGuard>
        ),
        children: [
            //#region Base
            {
                path: '',
                element: <Navigate to={pages.admin.jobs.path} replace />,
            },
            //#endregion Base
            //#region jobs
            {
                path: pages.admin.jobs.path,
                element: <JobsPage />,
            },
            {
                path: pages.admin.jobDetail.path,
                element: <JobDetailPage />,
            },
            {
                path: pages.admin.jobWorkerDetail.path,
                element: <JobWorkerDetailPage />,
            },
            {
                path: pages.admin.jobCreate.path,
                element: <JobCreatePage />,
            },
            //#endregion jobs
            //#region users
            {
                path: pages.admin.users.path,
                element: <UsersPage />,
            },
            {
                path: pages.admin.userDetail.path,
                element: <UserDetailPage />,
            },
            //#endregion users
            //#region crowds
            {
                path: pages.admin.crowds.path,
                element: <CrowdsPage />,
            },
            {
                path: pages.admin.crowdDetail.path,
                element: <CrowdDetailPage />,
            },
            //#endregion crowds
            //#region skills
            {
                path: pages.admin.skills.path,
                element: <SkillsPage />,
            },
            //#endregion skills
            //#region organisations
            {
                path: pages.admin.organisations.path,
                element: <OrganisationsPage />,
            },
            //#endregion organisations
            //#region Worker home 
            {
                path: pages.worker.home.path,
                element: <WorkerHomePage />,
            },
            //#endregion Worker home
            //#region WorkerJobs
            {
                path: pages.worker.jobs.path,
                element: <WorkerJobsPage />,
            },
            //#endregion WorkerJobs
            //#region Worker active Job
            {
                path: pages.worker.activeJob.path,
                element: <WorkerActiveJobPage />,
            },
            //#endregion Worker Active Job
        ],
    },
];

export default routes;

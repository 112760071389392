const pages = {
    auth: {
        root: 'auth',
        login: {
            name: 'login',
            path: '/auth/login'
        },
        register: {
            name: 'register',
            path: '/auth/register'
        },
        resetPassword: {
            name: 'resetPassword',
            path: '/auth/resetPassword'
        },
    },
    admin: {
        root: 'admin',
        jobs: {
            name: 'jobs',
            path: '/admin/jobs'
        },
        jobDetail: {
            name: 'jobs',
            path: '/admin/jobs/:jobId'
        },
        jobWorkerDetail: {
            name: 'jobs',
            path: '/admin/jobs/:jobId/:userId'
        },
        jobCreate: {
            name: 'jobs',
            path: '/admin/jobs/create'
        },
        users: {
            name: 'users',
            path: '/admin/users'
        },
        userDetail: {
            name: 'users',
            path: '/admin/users/:userId'
        },
        crowds: {
            name: 'crowds',
            path: '/admin/crowds'
        },
        crowdDetail: {
            name: 'crowds',
            path: '/admin/crowds/:crowdId'
        },
        skills: {
            name: 'skills',
            path: '/admin/skills'
        },
        organisations: {
            name: 'organisations',
            path: '/admin/organisations'
        },
    },
    worker: {
        root: 'worker',
        home: {
            name: 'home',
            path: '/worker/home'
        },
        jobs: {
            name: 'jobs',
            path: '/worker/jobs'
        },
        activeJob: {
            name: 'job',
            path: '/worker/job/:jobId'
        },
    },
    status: {
        root: 'status',
        unverified: {
            name: 'unverified',
            path: '/status/unverified'
        },
        statusComingSoon: {
            name: 'coming-soon',
            path: '/status/coming-soon'
        },
        statusMaintenance: {
            name: 'maintenance',
            path: '/status/maintenance'
        },
        status404: {
            name: '404',
            path: '/status/404'
        },
        status500: {
            name: '500',
            path: '/status/500'
        },
        statusSuccess: {
            name: 'success',
            path: '/status/success'
        },
        statusFailure: {
            name: 'failure',
            path: '/status/failure'
        },
        statusCancel: {
            name: 'cancel',
            path: '/status/cancel'
        }
    }
}

export default pages;